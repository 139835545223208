export const dim = {
    banner: "40px",
    clickable: {
        minHeight: "40px",
        minWidth: "80px",
    },
    maxWidth: "700px",
    phone: "480px",
    mid: "750px",
    padding: "30px"
}